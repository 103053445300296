<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Пользователи</h4>
          </div>
          <div class="on-offline-box">
            <p>65</p>
            <div class="status online"></div>
            <p>/</p>
            <p>165</p>
            <div class="status offline"></div>
          </div>
          <div class="d-flex align-items-center">
            <ValidationProvider
              v-slot="{ errors, reset }"
              class="w-100"
              tag="div"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label class="search">Поиск</label>
                <md-input
                  v-model="search_query"
                  class="search"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>
            <md-button class="md-raised md-primary search-btn">
              Поиск
            </md-button>
          </div>
        </md-card-header>
        <md-card-content>
          <UsersTable />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import UsersTable from "../../components/Tables/Users.vue";

export default {
  components: {
    UsersTable,
  },
  data() {
    return {
      search_query: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.d-flex {
  gap: 20px;
}
.search {
  color: #fff !important;
  -webkit-text-fill-color: unset !important;
}
.on-offline-box {
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    margin: 0;
  }
}
.status {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
::v-deep .online {
  background-color: green;
}

::v-deep .offline {
  background-color: red;
}
</style>
