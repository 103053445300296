<template>
  <div>
    <div class="admins-navbar">
      <md-tabs @md-changed="(tabId) => (activeTab = tabId)">
        <md-tab id="tab-users-no-banned" md-label="Все пользователи" />
        <md-tab id="tab-users-banned" md-label="Заблокированные" />
      </md-tabs>
    </div>
    <div class="position-table">
      <md-table v-model="sortUsers">
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          class="cursor-pointer"
          @click="
            openModal({
              modalName: 'UserInfo',
            })
          "
        >
          <md-table-cell md-label="ID" md-sort-by="id">
            <span>{{ item.id }}</span>
          </md-table-cell>
          <md-table-cell md-label="Ник пользователя">
            <span class="text ws-nowrap">{{ item.login }}</span>
          </md-table-cell>
          <md-table-cell md-label="E-mail">
            <span class="text ws-nowrap">{{ item.email }}</span>
          </md-table-cell>
          <md-table-cell md-label="Дата последней активности">
            <span class="text">{{ item.last_activities }}</span>
          </md-table-cell>
          <md-table-cell md-label="Дата блокировки">
            <span v-show="item.ban_date" class="text">{{ item.ban_date }}</span>
          </md-table-cell>
          <md-table-cell md-label="Дата удаления">
            <span v-show="item.delete_date" class="text">{{
              item.delete_date
            }}</span>
          </md-table-cell>
          <md-table-cell md-label="Статус">
            <div :class="`status ${item.status}`"></div>
          </md-table-cell>
          <md-table-cell md-label="Возможность">
            <div class="edit-and-delete-block">
              <md-button
                v-if="activeTab !== 'tab-users-banned' && !item.delete_date"
                class="md-simple md-primary"
                @click.stop="
                  openModal({
                    modalName: 'UserBanned',
                    modalData: `${item.login}`,
                  })
                "
                >Забанить</md-button
              >
              <md-button
                v-if="item.delete_date || activeTab == 'tab-users-banned'"
                class="md-simple md-primary"
                @click.stop="
                  openModal({
                    modalName: 'UserRecover',
                    modalData: `${item.login}`,
                  })
                "
                >Восстановить</md-button
              >
              <md-button
                v-if="!item.delete_date"
                class="md-just-icon md-simple md-primary"
                @click.stop="
                  openModal({
                    modalName: 'DeleteConfirm',
                    modalData: `пользователя ${item.login}`,
                  })
                "
              >
                <md-icon>delete</md-icon>
                <md-tooltip md-direction="top">Удалить</md-tooltip>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <Pagination
        :current-page="pagination.current_page"
        :last-page="pagination.last_page"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/ui/Pagination";
import { mapMutations } from "vuex";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      activeTab: "",
      pagination: {
        current_page: 1,
        last_page: 2,
      },
      users: [
        {
          id: 1,
          login: "vasya_123",
          email: "vasya83@mail.ru",
          last_activities: "12.01.2023",
          ban_date: "22.03.2023",
          status: "online",
        },
        {
          id: 2,
          login: "petya_nagibator",
          email: "petr@yandex.ru",
          last_activities: "12.01.2022",
          status: "offline",
        },
        {
          id: 3,
          login: "pustoi_login",
          email: "zapadnaya_pocha_dlinnaya@gmail.com",
          last_activities: "12.02.2023",
          status: "offline",
        },
        {
          id: 4,
          login: "petya_nagibator",
          email: "petr@yandex.ru",
          last_activities: "12.01.2022",
          ban_date: "22.06.2023",
          status: "offline",
        },
        {
          id: 5,
          login: "petya_nagibator",
          email: "petr@yandex.ru",
          last_activities: "12.01.2022",
          delete_date: "22.12.2023",
          status: "offline",
        },
        {
          id: 6,
          login: "petya_nagibator",
          email: "petr@yandex.ru",
          last_activities: "12.01.2022",
          status: "offline",
        },
      ],
    };
  },
  computed: {
    sortUsers() {
      if (this.activeTab == "tab-users-banned") {
        return this.users.filter((user) => user.ban_date);
      }
      return this.users.filter((user) => !user.ban_date);
    },
  },
  methods: {
    ...mapMutations(["openModal"]),
  },
};
</script>

<style lang="scss" scoped>
.text {
  word-break: break-word;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages {
  margin-right: 20px;
  margin-left: 20px;
}

.status {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-left: 20px;
}
.admins-navbar {
  margin-top: 40px;
  margin-bottom: 20px;

  &::v-deep {
    .md-tabs-navigation {
      background-color: #58b05c !important;
      padding: 20px;
    }
  }
}
</style>
